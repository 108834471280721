<script>
import { Table } from 'ant-design-vue'
import api from '@/command/api'
import { getAction, postAction, putAction } from '@/command/netTool'

export default {
  props: {
    dataCheck: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      ...api.command.getState(),
      tableData: [],
      selectDataArr: [],
      selectDataObj: []
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectDataArr,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectDataArr = selectedRowKeys
          this.selectDataObj = selectedRows
          if (this.tableData.length == this.selectDataArr.length) {
            this.tableData.forEach(e => {
              e.activitys = true
              e.partys = true
              e.volunteers = true
            })
          } else if (this.selectDataArr.length == 0) {
            this.tableData.forEach(e => {
              e.activitys = false
              e.partys = false
              e.volunteers = false
            })
          } else {
            this.tableData.forEach(e => {
              this.selectDataObj.forEach(e1 => {
                if (e.name == e1.name) {
                  e.activitys = true
                  e.partys = true
                  e.volunteers = true
                }
              })
            })
          }
        }
      }
    }
  },
  mounted() {
    getAction('/api/farmActivityDisplayPalm/list?publishingType=1').then(res => {
      if (res.code == 200) {
        let arr = res.data
        arr.forEach(e => {
          e.activitys = false
          e.partys = false
          e.volunteers = false
          e.bonus_points = null
          this.dataCheck.forEach(el => {
            let isShow = el.farmSectionType.split(',')
            if (el.palmName == e.name) {
              if (isShow.includes('0')) {
                e.activitys = true
              }
              if (isShow.includes('1')) {
                e.partys = true
              }
              if (isShow.includes('2')) {
                e.volunteers = true
              }
              e.bonus_points = el.bonusPoints ? el.bonusPoints : null
            }
          })
        })
        this.tableData = arr
      } else {
        this.$message.error(res.msg)
      }
    })
  },
  methods: {
    sumbit() {
      this.$emit('success', this.tableData)
    },
    getColumns() {
      let _this = this
      return [
        {
          title: '全部',
          dataIndex: 'name',
          align: 'left'
        },
        {
          title: '活动列表',
          dataIndex: 'activitys',
          align: 'center',
          customRender: function customRender(text, record, index) {
            let onInput = e => {
              record.activitys = e.target.checked
              _this.handleCheckBox(record)
            }
            return <a-checkbox onChange={onInput} checked={text}></a-checkbox>
          }
        },
        {
          title: '党建板块',
          dataIndex: 'partys',
          align: 'center',
          customRender: function customRender(text, record, index) {
            let onInput = e => {
              record.partys = e.target.checked
              _this.handleCheckBox(record)
            }
            return <a-checkbox onChange={onInput} checked={text}></a-checkbox>
          }
        },
        {
          title: '志愿者板块',
          dataIndex: 'volunteers',
          align: 'center',
          customRender: function customRender(text, record, index) {
            let onInput = e => {
              record.volunteers = e.target.checked
              _this.handleCheckBox(record)
            }
            return <a-checkbox onChange={onInput} checked={text}></a-checkbox>
          }
        },
        {
          title: '奖励积分',
          dataIndex: 'bonus_points',
          width: 120,
          align: 'center',
          customRender: function customRender(text, record, index) {
            let onInput = e => {
              record.bonus_points = e.target.value
            }
            return <a-input value={text} onChange={onInput}></a-input>
          }
        }
      ]
    },
    handleCheckBox(record) {
      if (this.selectDataArr.indexOf(record.name) !== -1) {
        this.selectDataArr.splice(
          this.selectDataArr.findIndex(item => item === record.name),
          1
        )
      }

      if (record.activitys && record.partys && record.volunteers) {
        this.selectDataArr.push(record.name)
      }
    }
  },
  render() {
    if (this.tableData.length > 0) {
      return (
        <Table
          pagination={false}
          rowSelection={this.rowSelection}
          dataSource={this.tableData}
          columns={this.getColumns()}
          rowKey="name"
          //   customRow={record => {
          //     return {
          //       on: {
          //         // 事件
          //         click: event => {
          //           console.log(record, '==record==')
          //         } // 点击行
          //       }
          //     }
          //   }}
        />
      )
    }
  }
}
</script>
