<script>
import { Table } from 'ant-design-vue'
import api from '@/command/api'
import { getAction, postAction, putAction } from '@/command/netTool'

export default {
  props: {
    dataCheck: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      ...api.command.getState(),
      tableData: [],
      selectDataArr: [],
      selectDataObj: []
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectDataArr,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectDataArr = selectedRowKeys
          this.selectDataObj = selectedRows
          if (this.tableData.length == this.selectDataArr.length) {
            this.tableData.forEach(e => {
              e.activitys = true
            })
          } else if (this.selectDataArr.length == 0) {
            this.tableData.forEach(e => {
              e.activitys = false
            })
          } else {
            this.tableData.forEach(e => {
              this.selectDataObj.forEach(e1 => {
                if (e.name == e1.name) {
                  e.activitys = true
                } else {
                  e.activitys = false
                }
              })
            })
          }
        }
      }
    }
  },
  mounted() {
    getAction('/api/farmActivityDisplayPalm/list?publishingType=0').then(res => {
      if (res.code == 200) {
        let arr = res.data
        arr.forEach(e => {
          this.dataCheck.forEach(el => {
            if (el.palmName == e.name && el.farmSectionType == '0') {
              e.activitys = true
            }
          })
        })
        this.tableData = arr
      } else {
        this.$message.error(res.msg)
      }
    })
  },
  methods: {
    sumbit() {
      this.$emit('success', this.tableData)
    },
    getColumns() {
      let _this = this
      return [
        {
          title: '全部',
          dataIndex: 'name',
          align: 'left'
        },
        {
          title: '活动列表',
          dataIndex: 'activitys',
          align: 'center',
          customRender: function customRender(text, record, index) {
            let onInput = e => {
              record.activitys = e.target.checked
              if (record.activitys) {
                _this.selectDataArr.push(record.name)
              } else {
                _this.tableData.forEach(e => {
                  if (e.name == record.name) {
                    e.activitys = false
                  }
                })
                _this.selectDataArr.splice(
                  _this.selectDataArr.findIndex(item => item === record.name),
                  1
                )
              }
            }

            return <a-checkbox onChange={onInput} checked={text}></a-checkbox>
          }
        }
      ]
    }
  },
  render() {
    if (this.tableData.length > 0) {
      return (
        <Table
          pagination={false}
          rowSelection={this.rowSelection}
          dataSource={this.tableData}
          columns={this.getColumns()}
          rowKey="name"
          //   customRow={record => {
          //     return {
          //       on: {
          //         // 事件
          //         click: event => {
          //           console.log(record, '==record==')
          //         } // 点击行
          //       }
          //     }
          //   }}
        />
      )
    }
  }
}
</script>
